<template>
  <div class="FenceIndex">
    <div v-loading="loading"></div>
    <h1 class="FenceIndex-title" v-if="clientWidth > 768">习 剑</h1>
    <div class="FenceIndex-content" :class="clientWidth > 768 ? 'flex': ''">
      <div class="FenceIndex-content-left" ref="navState">
        <div class="fence-nav-item" v-for="(item, index) in navList" :key="item.id">
          <div v-if="isPartner(index)" style="position: relative;">
            <div class="fence-nav-item-title" :class="clientWidth > 768 ? 'flex3': 'flex0'" @click="mobileNavTab(index)">
              <b>{{ item.title }}</b>
              <i class="el-icon-caret-bottom"></i>
            </div>
            <el-collapse-transition>
              <div class="fence-nav-item-menu" v-show="(clientWidth > 768 && !mobileNavState[index]) || (clientWidth < 769 && mobileNavState[index])">
                <div class="fence-nav-item-menu-item" v-for="itemChild in item.list" :key="itemChild.id" @click="navTab(index, itemChild.value)" :class="NavActive(index, itemChild.value) ? 'nav-active' : ''">
                  {{itemChild.text}}
                </div>
              </div>
            </el-collapse-transition>
          </div>
        </div>
      </div>
      <div class="FenceIndex-content-right">
        <div class="input-search">
          <el-input v-model.trim="params.title" type="text" @keyup.enter.native="Search" placeholder="请输入您要查询的内容"></el-input>
          <i class="el-icon-search cur" @click="Search"></i>
        </div>
        <div class="FenceIndex-content-right-head">
          <el-form ref="form" label-width="60px" size="mini">
            <el-form-item v-if="clientWidth > 750" label="行业：">
              <el-radio-group v-model="params.industryId" size="medium" @change="changeRadio">
                <el-radio-button v-for="item in module.industrys" :key="item.value" :label="item.value" border class="check-item"> {{ item.text }} </el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="二级：" v-if="selectList.length > 0">
              <el-radio-group v-model="params.industryIdSecond" size="medium" @change="changeSecond">
                <el-radio-button v-for="item in selectList" :key="item.value" :label="item.value" class="check-item">{{ item.text }}</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="推荐：">
              <el-radio-group v-model="params.sort" size="medium" @change="changeRecommend">
                <el-radio-button v-for="item in module.sorts" :key="item.value" :label="item.value" class="check-item">{{ item.text }}</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="dataState">
          <div class="FenceIndex-content-right-list" v-if="resData.data.length" :class="clientWidth > 768 ? 'flex4': ''">
            <div class="FenceIndex-content-right-list-item" v-for="(item, index) in resData.data" :key="item.id">
              <div class="FenceIndex-content-right-list-item-banner cur" @click="goFenceDetail(item.id)">
                <div v-if="item.isHaveVideo" class="FenceIndex-content-right-list-item-video flex0">
                  <img src="@/assets/PC/fenceDetail/player.png" alt="" width="20%">
                </div>
                <img :src="item.imgUrl" alt="" width="100%">
              </div>
              <div class="FenceIndex-content-right-list-item-content">
                <div class="FenceIndex-content-right-list-item-title cur" :class="clientWidth < 769 ? 'hidden' : 'hidden2'" @click="goFenceDetail(item.id)">
                  {{ item.title }}
                </div>
                <div class="flex3">
                  <div class="FenceIndex-content-right-list-item-date">
                    {{ item.releaseTimeStr }}
                  </div>
                  <van-tag v-if="item.isNew" round type="danger" class="FenceIndex-content-right-list-item-IsNew">NEW</van-tag>
                </div>
                <p class="FenceIndex-content-right-list-item-introduction cur" :class="clientWidth > 768 ? 'hidden2' : 'hidden'" @click="goFenceDetail(item.id)">
                  {{ item.introduction }}
                </p>
                <div class="FenceIndex-content-right-list-item-tags flex4">
                  <div v-for="(items, index) in item.tags" :key="index" class="tag-item">{{ items }}</div>
                </div>
                <div class="FenceIndex-content-right-list-item-operation flex3" v-if="clientWidth < 769">
                  <div class="xy-icon">
                    <img src="@/assets/PC/studyF/study_type_k.png" />
                    <span> {{ item.pv }} </span>
                  </div>
                  <div class="xy-icon cur" @click="IsLikes(item.id, item.isPraise, index)" title="点赞">
                    <img v-if="item.isPraise" src="@/assets/PC/studyF/Liked.png" class="animate__animated animate__fadeIn"/>
                    <img v-else src="@/assets/PC/studyF/Like.png" />
                    <span> {{ item.praiseNum }} </span>
                  </div>
                  <div class="xy-icon" @click="Enshrines(item.id, item.isCollect, index)" title="收藏">
                    <van-icon v-if="item.isCollect" name="like" class="xy-icon-Collect animate__animated animate__fadeIn" color="#6b18a8"/>
                    <van-icon v-else name="like-o" class="xy-icon-Collect" />
                  </div>
                </div>
                <div class="FenceIndex-content-right-list-item-bottom" v-if="clientWidth > 768">
                  <div class="FenceIndex-content-right-list-item-operation flex2">
                    <div class="xy-icon">
                      <img src="@/assets/PC/studyF/study_type_k.png" />
                      <span> {{ item.pv }} </span>
                    </div>
                    <div class="xy-icon cur" @click="IsLikes(item.id, item.isPraise, index)" title="点赞">
                      <img v-if="item.isPraise" src="@/assets/PC/studyF/Liked.png" class="animate__animated animate__fadeIn"/>
                      <img v-else src="@/assets/PC/studyF/Like.png" />
                      <span> {{ item.praiseNum }} </span>
                    </div>
                    <div class="xy-icon" @click="Enshrines(item.id, item.isCollect, index)" title="收藏">
                      <van-icon v-if="item.isCollect" name="like" class="xy-icon-Collect animate__animated animate__fadeIn" color="#6b18a8"/>
                      <van-icon v-else name="like-o" class="xy-icon-Collect"/>
                    </div>
                  </div>
                  <div class="xy-icon-nav">
                    <div class="xy-icon-nav-box">
                      <img class="icon-bg cur" src="@/assets/PC/studyF/xz_l.png" @click="goFenceDetail(item.id)"/>
                      <i class="el-icon-top-right cur" @click="goFenceDetail(item.id)"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="noData">
            <img src="@/assets/PC/profile/listNone.png" alt="" />
          </div>
          <div class="Page tr">
            <el-pagination v-if="clientWidth > 768"
              @current-change="handleCurrentChange"
              :total="resData.recordCount"
              :page-size="resData.pageSize"
              :current-page="resData.pageIndex"
              background
              layout="pager, next"
              next-text="下一页"
              hide-on-single-page
            >
            </el-pagination>
            <div v-else class="loadList flex0">
              <div v-if="!IsMobileList" class="loadList0" @click="loadListMb">加载更多</div>
              <div v-if="clientWidth < 750 && IsMobileList && resData.data.length > 0" class="tc notDetail">
                <div>
                  没有更多内容了~
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 登录执行弹窗 -->
    <shadeAlert :shadeConfig="shadeConfig" @closeAlert="closeAlertChange"/>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { debounce,GetWxShares } from '@/utils/common'
import * as Fence from '@/api/library.js'
import { dataState } from '@/api/all.js'
import shadeAlert from "@/components/shadeAlert/index.vue"

export default {
  name: 'FenceIndex',
  components: {
    shadeAlert,
  },
  data() {
    return {
      // !弹窗配置
      shadeConfig: {},
      // !页面loading
      loading: true,
      // !移动端导航展示状态
      mobileNavState: [false, false, false],
      selectList: [],
      params: {
        // @ 产品id
        productId: this.$route.query.Id ? this.$route.query.Id : '0',
        // @ 类型id
        typeId: this.$route.query.typesId ? this.$route.query.typesId : '0',
        // @ 方向id
        directionId: this.$route.query.directionsId ? this.$route.query.directionsId : '0',
        // @ 行业id
        industryId: '0',
        // @ 行业二级id
        industryIdSecond: '0',
        // @ 推荐
        sort: '0',
        // @ 页数
        page: 1,
        // @ 条数
        limit: 9,
        // 搜索条件
        title: ''
      },
      // !数据是否返回
      dataState: false,
      // !移动端加载更多
      IsMobileList: false,
      resData: {
        data: [],
        pageCount: 10,
        pageIndex: 1,
        pageSize: 9,
        recordCount: 0
      },

      industrysData: this.$store.state.Module.industrys
    }
  },
  mounted() {
    const share = {
      title: this.$route.meta.title,
      desc: ''
    }
    GetWxShares(share);
    this.GetInit();
    document.addEventListener("click", e =>{
      if (document.documentElement.clientWidth < 750) {
        if(this.$refs.navState) {
          if(!this.$refs.navState.contains(e.target)) {
            this.mobileNavState = [false, false, false]
          }
        }
      }
    });

    // 数据统计
    const data ={
      "visitType": 1,
      "gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
      "url": window.location.href,
      "pageUrl": "FenceIndex",
      "relationId": this.$route.query.Id
    }
    dataState(data).then(res => {
      sessionStorage.setItem('gid',res.data);
    });
  },
  methods: {
    // !关闭登录指引弹窗
    closeAlertChange(state) {
      this.shadeConfig.show = state;
    },
    // !搜索
    Search() {
      this.loading = true;

      this.params.page = 1;
      this.params.limit = 9;
      this.GetInit();
    },
    // !是否显示 方向 筛选
    isPartner(index) {
      return (this.clientWidth > 750 && index !== 2) || (this.clientWidth > 750 && index == 2 && this.Infos.isPartner) || this.clientWidth < 750
    },
    // !移动端 筛选
    mobileNavTab(index) {
      if(this.clientWidth < 769) {
        if(index == 0) {
          this.mobileNavState = [true, false, false]
        } else if(index == 1) {
          this.mobileNavState = [false, true, false]
        } else if(index == 2) {
          this.mobileNavState = [false, false, true]
        }
      }
    },
    // !点击左侧切换
    navTab(index, ChildValue) {
      this.params.title = '';
      if(index == 0&&this.$route.query.Id!=ChildValue) {
        this.$router.push('/Home/Fence/FenceIndex?Id=' + ChildValue);
        return false
      } else if (index == 1) {
        this.params.typeId = ChildValue
      } else if (this.clientWidth > 768 && index == 2) {
        this.params.directionId = ChildValue
      } else if (this.clientWidth < 769 && index == 2) {
        if(ChildValue != '0'){
          this.selectList = this.filterSelect(ChildValue)
        } else {
          this.selectName = ''
          this.selectList = []
        }
        this.params.industryId = ChildValue
      }
      this.params.page = 1;
      this.GetInit();
    },
    // !是否选中状态
    NavActive(index,childId) {
      return (index == 0 && childId === this.params.productId) || (index == 1 && childId === this.params.typeId) || (this.clientWidth > 768 &&  index == 2 && childId === this.params.directionId) || (this.clientWidth < 769 &&  index == 2 && childId === this.params.industryId)
    },
    // !行业筛选
    changeRadio (id) {
      if(id != '0'){
        this.selectList = this.filterSelect(id)
      } else {
        this.selectName = ''
        this.selectList = []
      }
      
      this.params.page = 1;
      this.params.title = '';
      this.GetInit();
    },
    // !行业二级筛选
    changeSecond() {
      this.params.page = 1;
      this.params.title = '';
      this.GetInit();
    },
    // !推荐筛选
    changeRecommend () {
      this.params.page = 1;
      this.params.title = '';
      this.GetInit();
    },
    filterSelect(id) {
      var arr1 = [{
        value: '0',
        text: '全部'
      }]
      var arr2 = []
      this.industrysData.filter(item => {
        if(item.value == id){
          arr2 = item.list
        }
      })
      return arr1.concat(arr2)
    },
    // !页数
    handleCurrentChange: function (currentPage) {
      this.params.page = currentPage;
      this.GetInit();
    },
    // !获取数据
    GetInit(IsMobile) {
      if(this.clientWidth < 769) {
        this.mobileNavState = [false, false, false]
      }
      this.loading = true
      Fence.GetLibrary(this.params).then(res => {
        this.loading = false
        if (res.status === 1) {
          if(IsMobile) {
            this.resData.data = this.resData.data.concat(res.data)
          } else {
            this.resData = res
          }
          if(this.clientWidth < 769) {
            if(this.resData.data.length == this.resData.recordCount) {
              this.IsMobileList = true
            } else {
              this.IsMobileList = false
            }
          }
          this.dataState = true
        } else {
          this.$toast(res.message)
        }
      })
    },
    // !去详情页
    goFenceDetail(Id) {
                        // 取消登录限制
    //   if(!this.$store.state.token){
    //     this.shadeConfig = {
    //       show: true, // 展示隐藏弹窗
    //       closeIcon: true, // 是否需要关闭按钮
    //       title: "您还没有登录，<br/>请登录后再来学习吧！",
    //       text: "", // 弹窗内容
    //       btnType: "link", // 按钮形式
    //       btnList: [
    //         {
    //           text: '已有剑盟账号，前往登录',
    //           link: '/Login?redirectUrl=' + this.$route.fullPath,
    //         },
    //         {
    //           text: '没有账号，立即注册',
    //           link: '/Register?redirectUrl=' + this.$route.fullPath,
    //         },
    //       ],
    //     };
    //     return false;
    //   }
      this.$router.push("/Home/FenceDetail?id=" + Id);
    },
    // !点赞
    IsLikes(id, IsTrue, index) {
      const data = { resourcesId: id, type: 0 };
      if(!this.$store.state.token){
        this.$toast('请登录后操作!')
        return false;
      }
      debounce(() => {
        if(!IsTrue){
          Fence.Praise(data).then( res => {
            this.$toast(res.message);
            if(res.status == 1) {
              this.resData.data[index].isPraise = true
              this.resData.data[index].praiseNum++
            }
          });
        }else{
          Fence.UnPraise(data).then( res => {
            this.$toast(res.message);
            if(res.status == 1) {
              this.resData.data[index].isPraise = false
              this.resData.data[index].praiseNum--
            }
          });
        }
      }, 1000);
    },
    // !收藏
    Enshrines(id, IsTrue, index) {
      const data = { libraryId: id };
      if(!this.$store.state.token){
        this.$toast('请登录后操作!')
        return false;
      }
      debounce(() => {
        if (!IsTrue) {
          Fence.Collect(data).then(res => {
            this.$toast(res.message);
            if(res.status === 1) {
              this.resData.data[index].isCollect = true
            }
          });
        } else {
          Fence.UnCollect(data).then(res => {
            this.$toast(res.message);
            if(res.status === 1) {
              this.resData.data[index].isCollect = false
            }
          });
        }
      }, 1000);
    },
    // !点击加载更多
    loadListMb() {
      this.loading = true;
      this.params.page++
      this.GetInit(true);
    }
  },
  computed: {
    ...mapState(['Infos', 'clientWidth']),
    ...mapGetters(['module']),
    navList() {
      let list = [];
      if(this.clientWidth > 750) {
        list =[
          { id: 10001, title: '产品与方向', list: this.module.products },
          { id: 10002, title: '类型', list: this.module.types },
          // { id: 10003, title: '方向', list: this.module.direction }
        ]
      } else {
        list =[
          { id: 10001, title: '产品与方向', list: this.module.products },
          { id: 10002, title: '类型', list: this.module.types },
          { id: 10003, title: '行业', list: this.module.industrys }
        ]
      }
      return list
    }
  },
  watch: {
    $route: {
      handler(val) {
        this.params.page = 1;
        if(this.$route.query.Id == null || this.$route.query.Id == ""){
          this.params.productId = '0'
        }else{
          this.params.productId = val.query.Id;
        }
        this.GetInit();
        // 数据统计
        const data ={
          "visitType": 1,
          "gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
          "url": window.location.href,
          "pageUrl": "FenceIndex",
          "relationId": this.$route.query.Id
        }
        dataState(data).then(res => {
          sessionStorage.setItem('gid',res.data);
        });
      },
      // 深度观察监听
      deep: true,
    },
  }
}
</script>

<style lang="scss" scoped>
  @import './index.scss';
</style>
import request from '@/utils/request'
// !获取产品-类型-行业
export function GetLibrary(params) {
  return request({
    url: '/Library/GetLibrary',
    method: 'GET',
    params
  })
}

// !点赞
export function Praise(data) {
  return request({
    url: '/Library/Praise',
    method: 'POST',
    data
  })
}
// !取消点赞
export function UnPraise(data) {
  return request({
    url: '/Library/UnPraise',
    method: 'POST',
    data
  })
}

// !点赞
export function Collect(data) {
  return request({
    url: '/Library/Collect',
    method: 'POST',
    data
  })
}
// !取消点赞
export function UnCollect(data) {
  return request({
    url: '/Library/UnCollect',
    method: 'POST',
    data
  })
}

// !获取详情页数据
export function GetDetails(params) {
  return request({
    url: '/Library/GetDetails',
    method: 'get',
    params
  })
}

// !播放视频数量记录
export function AddEventLogs(data) {
  return request({
    url: '/Library/AddEventLogs',
    method: 'post',
    data
  })
}

// !增加积分
export function AddIntegral(data) {
  return request({
    url: '/Library/AddIntegral',
    method: 'post',
    data
  })
}

// !下载PDF
export function getDownloadAPI(url) {
  return request({
    url: url,
    responseType: 'blob',
    method: 'GET'
  })
}
// ! 触发学习完成（文章阅读版）
export function DetailsLearn(data) {
  return request({
    url: '/Library/DetailsLearn',
    method: 'post',
    data
  })
}
// ! 触发学习完成（课程学习）
export function SalesSkillLearn(data) {
  return request({
    url: '/Library/SalesSkillLearn',
    method: 'post',
    data
  })
}
// ! 触发学习完成（PDF阅览版）
export function PDFLearn(data) {
  return request({
    url: '/Library/PDFLearn',
    method: 'post',
    data
  })
}
// ! 触发学习完成（视频学习版）
export function VideoLearning(data) {
  return request({
    url: '/Library/VideoLearning',
    method: 'post',
    data
  })
}